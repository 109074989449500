import React, {useContext, useState} from 'react';
import UserContext from "../context/user";
import {findEmployee} from "../../employees";
import {TypeForm} from "../components/typeform";

export const EngineeringAssessmentForm = (props) => {
  const user = useContext(UserContext)
  const formId = 'https://certainaffinity.typeform.com/to/fgdGJosm?&alias='+user.employee.id+'&manageralias='+user.manager.id+'&department='+user.employee.department+'&discipline='+user.employee.discipline+'&location='+user.employee.location
    
  return(
  	<>
  		<br/>
  		<div style={{color: 'black', fontSize: '24px', fontWeight: 'bold'}}>
  		Engineering Skills Self-Assessment for {user.employee.name}
  		</div>
      <br/>

		<TypeForm
      formId={formId}
      onSubmit={() => {
      	console.log("Engineering Skills Assessment complete")

        if (props.onSubmit) {
          props.onSubmit()
        } 
      }}
	  />
    </>
  )
}
