import React, {useContext, useState} from 'react'
import UserContext from '../context/user'
import {allEmployees} from '../../employees'
import {Typeahead} from 'react-bootstrap-typeahead'
import Header from '../components/header'
import {SelfReviewForm} from '../components/self-review'
import Section from '../components/section'
import {EngineeringAssessmentForm} from "../components/engineering-assessment"
import Alert from "react-bootstrap/alert"

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'


const EngineeringAssessmentPage = () => {
  const baseUrl = window.location.protocol+'//'+window.location.host+'/'

  const onSubmission = function() {
    document.getElementById("thankYouPrompt").style.display="block";
  }
  
  return (
    <>
      <Header/>
      <EngineeringAssessmentForm
        onSubmit={onSubmission}
      />

      <br/>
      <div id="thankYouPrompt" style={{color: 'black', fontSize: '24px', fontWeight: 'bold', display: 'none'}}>
      Thank you! Click <a href={baseUrl+"peer-review"}>here</a> to proceed to the Peer Review step.
      </div>
    </>
  )
}

export default EngineeringAssessmentPage
